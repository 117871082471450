.apresentation{
    display: flex;
    flex-direction: column;
    p{
        font-family: Montserrat Alternates;
        font-size: 16px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: #333333;

    }
    a{
        max-width: 190px;
        background: #2F80ED;
        border-radius: 15px;
        padding: 13px 35px;
        text-decoration: none;
        border: 3px solid #2F80ED;
        color: white;
        display:block;
        transition: all 0.5s;
        &:hover {
            background: white;
            color: #2F80ED;
            transition: all 0.5s;
            border: 3px solid #2F80ED;
        }

    }
    img {
        width: 15px;
        height: 60px;
        align-self: center;
    
    }
    .img-bg { 
        display: none;
    }
}

@media (min-width:1200px) {
    .apresentation {
        flex-direction: row;
        margin-top: 50px !important;
        margin-bottom: 80px;
        .box-text {
            width: 40%;
        }
        a{
            margin-top: 40px;
    
        }
        .img-bg {
            width: 50%;
            margin-left: auto;
            display: block;
            img {
                width: 100%;
                height: 100%;
            }
           
        }
    }
    
}
  