
.pillars {
    background: rgba(47, 128, 237, 0.03);
    padding-top: 60px;
    margin-top: -30px;
    display: flex;
    flex-direction: column;

    h1 {
        height: 76px;   
        font-family: 'Montserrat Alternates';
        font-style: normal;
        font-weight: 800;
        font-size: 32px;
        line-height: 120%;
        letter-spacing: 0.02em;
        color: #4F4F4F;

    }
    p {
        font-family: Montserrat Alternates;
        font-size: 16px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0.02em;
        text-align: left;

    }
.cards {
    display: flex;
    overflow-x: scroll;
    padding-left: 10px;
    .card {
        margin-bottom: 40px;
        margin-top: 40px;
        margin-right: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-width: 246px;
        max-width: 246px;
        min-height: 260px;
        background: #FFFFFF;
        box-shadow: 0px 4px 16px rgb(170 194 231 / 59%);
        border-radius: 22px;
        padding: 20px;
        .title {
            font-family: Montserrat Alternates;
            font-size: 24px;
            font-weight: 700;
            line-height: 29px;
            letter-spacing: 0em;
            text-align: center;

        }
        .desc {
            font-family: Montserrat Alternates;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: center;

        }
    }
  
}
.arrow-h {
    width: 83px;
    align-self: center;
    margin-bottom: 40px;
    margin-top: 20px;
}
}


@media (min-width:1200px) {
    .pillars {
        align-items: center;
        margin-bottom: 40px;
        padding-bottom: 40PX;
        h1 {
            text-align: center;
        }
        p{
            text-align: center;
        }
        .cards {
            padding: 0;
            justify-self: center;
            overflow:hidden;
            justify-content: space-between;
            .card {
                background: transparent;
                box-shadow: none;
                margin: 0px;
                min-width: 230px;
                max-width: 230px;
            }
        }
        .arrow-h {
           display: none;
        }
    }
}

