.maintenance-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f5f5;
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .title {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .message {
    font-size: 20px;
    text-align: center;
    margin-bottom: 10px;
  }
  