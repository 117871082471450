.action {
    background: #2F80ED;
    border-radius: 8px;
    padding: 50px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
    h1 {
        font-family: 'Montserrat Alternates';
        font-style: normal;
        font-weight: 800;
        font-size: 32px;
        line-height: 120%;
        /* or 38px */
        
        letter-spacing: 0.02em;
        
        /* Gray 6 */
        
        color: #F2F2F2;

    }
    a {
        margin-top: 30px;
        background: #F2F2F2;
        border-radius: 15px;
        padding: 13px 35px;
        text-decoration: none;
        font-family: 'Montserrat Alternates';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 170%;
        /* identical to box height, or 31px */

        text-align: center;

        /* Blue 1 */

        color: #2F80ED;
    }
}

@media (min-width:1200px){
    .action {
        padding: 50px 0px;
    }
}