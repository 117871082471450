.tech {
    margin-top: 60px ;
    .title {
        font-family: Montserrat Alternates;
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0em;
        margin-bottom: 25px;
        margin-bottom: 25px;
    
    }
      .desc {
          font-family: Montserrat Alternates;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          letter-spacing: 0em;
          margin-bottom: 40px;
    
      }

    .cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
        img {
            margin: 10px;
            width: 30%;
            max-width: 200px;
            border: 1px solid #e2eaf6;
            padding: 10px;

            &.ionic {             
                padding-top: 20px;
                padding-bottom: 20px;
            }
            &.amazon {
                padding-top: 20px;
                padding-bottom: 17px;
            }
        }
    }
}

@media (min-width:1200px) {
    .tech {
        display: flex;
        margin-top: 80px;
        align-items: center;
        .box-text {
            width: 50%;
            .title {
                font-family: 'Montserrat Alternates';
                font-style: normal;
                font-weight: 800;
                font-size: 43px;
                line-height: 120%;
                /* identical to box height, or 52px */

                letter-spacing: 0.02em;

                /* Gray 1 */

                color: #333333;
            }
            .desc {

                font-family: 'Montserrat Alternates';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 187%;
                /* or 30px */

                letter-spacing: 0.02em;

                /* Gray 2 */

                color: #4F4F4F;
            }
            
        }
        .box-img{
            margin-left: auto;
            width: 50%;
            .cards {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                max-width: 800px;
                margin-left: 0;
                margin-right: 0;
                img {
                    &.ionic {             
                        padding-top: 34px;
                        padding-bottom: 29px;
                    }
                    &.amazon {
                        padding-top: 37px;
                        padding-bottom: 16px;
                    }
                }
            }
        }
    }
}