.partners {
    background: #2F80ED;
    font-family: Montserrat Alternates;
    font-size: 18px;
    font-weight: 600;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 60px;
    color: #F2F2F2;
    margin-bottom: 20px;

    .images{

    display: flex;
    align-items: center;
    overflow-x: scroll;
    img {
        margin-left: 20px;
        margin-right: 20px;
        max-width: 90px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    }

    ::-webkit-scrollbar {
        width: 3px;
        border-radius: 25px;
        height: 9px;
      }
      
      /* Track */
      ::-webkit-scrollbar-track {
        background: rgb(247 247 247 / 0%);
        border-radius: 25px;
        height: 2px;
      }
       
      /* Handle */
      ::-webkit-scrollbar-thumb {
         background: rgb(255 255 255 / 48%);
          border-radius: 25px;
          width: 2px;
          height: 2px; 
      }
  
}
.arrow {
    width: 15px;
    height: 60px;
    align-self: center;
    margin-left: 50%;

}

@media (min-width:1200px) {
    .partners {
        .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            p {
                margin: 0px;
            }
            .images{ 
                width: 100%;
                margin-top: 30px;
                justify-content: space-evenly;
                img {
                    max-width: 120px;
                }
            }
        }


    }
}