.about {
    h1 {
        font-family: "Montserrat Alternates";
        font-style: normal;
        font-weight: 800;
        font-size: 32px;
        line-height: 120%;
        letter-spacing: 0.02em;
        color: #4F4F4F;
        
    }
    p {
    font-family: Montserrat Alternates;
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-bottom: 60px;

    }
    .arrow {
        width: 15px;
        height: 60px;
        align-self: center;
        margin-left: 50%;
    
    }
}

@media (min-width:1200px) {
    .about-row {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        h1 {
            font-family: Montserrat Alternates;
            font-size: 43px;
            font-weight: 800;
            line-height: 52px;
            letter-spacing: 0.02em;
            text-align: left;

        }
        .box-text {
            width: 40%;
            margin-right: auto;
        }
        .box-img {
            width: 50%;
            zoom: 1.5
        }
        
    }
}