.App {
  text-align: center;
}

.bg-w {
  background: white;
}

body[data-theme=dark] {
 background: white !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container{
  padding-left: 20px;
  padding-right: 20px;
}
.full-w {
  width: 100%;
}


@media (min-width:320px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ }
@media (min-width:480px)  { /* smartphones, Android phones, landscape iPhone */ }
@media (min-width:600px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ }
@media (min-width:801px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }

@media (min-width:1200px) {
  .container {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
}

a {
  text-decoration: none;
}

.text {
  font-family: 'Montserrat Alternates';
}

$spaceamounts: (5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }
  
    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}


.row {
  display: flex;
}
.col {
  display: flex;
  flex-direction: column;
}
::-webkit-scrollbar {
  width: 3px;
  border-radius: 25px;
  height: 9px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #b3c3e840;
    border-radius: 25px;
    height: 2px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(135, 163, 240);
  background: #005ff67a;
    border-radius: 25px;
    width: 2px;
    height: 2px; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(68, 116, 212); 
}