.header {
    padding-top: 20px;
    justify-content: space-between;
    position: sticky; top: 0;
    background: white;
    z-index: 10;
    transition: all 0.5s;
    padding-bottom: 10px;
    &.shadow {
        box-shadow: -20px -10px 20px 0px grey;
        transition: all 0.5s;
    }
    .container {
        display: flex;
        align-items: center;
    }
    nav {
        margin-left: auto;
    }
    .nav {
        display: none
    }
    .logo {
        background-blend-mode: exclusion;
        background-image: url('../../assets/img/Logo Inovando_Positivo (1) 1.png');
        height: 28px;
        width: 113px;
        border-radius: 0px;        
        background-size: contain;
        background-repeat: no-repeat;
    }
    .fale-conosco {
        height: 57px;
        width: 169px;
        left: 0px;
        top: 0px;
        border-radius: 15px;    
        border: 3px solid #2F80ED;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #2F80ED;
        margin-left: auto;
        transition: all 0.5s;
        cursor: pointer;
        &:hover {
            background: #2F80ED;
            color: white;
            transition: all 0.5s;
        }
        
    }
    
}


@media (min-width:1200px) {
   .header {
    position: sticky; top: 0;
    background: white;
    z-index: 10;
    transition: all 0.5s;
    padding-bottom: 20px;
    &.shadow {
        box-shadow: -20px -10px 20px 0px grey;
        transition: all 0.5s;
    }
    .container {
        display: flex;
        align-items: center;
        width: 100%;
        .fale-conosco {
            margin-left: auto;
        }
    }
    .nav {
        margin-left: auto;
        list-style: none;
        display: flex;
        justify-self: center;


        li {
            margin-right: 80px;
            a {
                text-decoration: none;
                font-family: Montserrat Alternates;
                font-size: 18px;
                font-weight: 600;
                line-height: 31px;
                letter-spacing: 0em;
                text-align: center;
                color: #333333;
            }
        }

        .hover-underline-animation {
            display: inline-block;
            position: relative;
          }
          
          .hover-underline-animation::after {
            content: '';
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 2px;
            bottom: 0;
            left: 0;
            background-color: #333333;
            transform-origin: bottom right;
            transition: transform 0.25s ease-out;
          }
          
          .hover-underline-animation:hover::after {
            transform: scaleX(1);
            transform-origin: bottom left;
          }
    }
   }
}