.lgpd {
margin-top: 80px;
background: #2F80ED;
border-radius: 8px;
padding: 56px 24px;
    .title {
        font-family: Montserrat Alternates;
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0em;
        margin-bottom: 25px;
        color: #F2F2F2;;
    
    }
      .desc {
          font-family: Montserrat Alternates;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          letter-spacing: 0em;
          color: #F2F2F2;;
    
      }
      .box-img {
        display: none;
      }
}

@media (min-width: 1200px) {
    .lgpd {
        display: flex;
        padding: 50px 0px;
        .box-text {
            width: 50%;
            padding-left: 64px;
            .title {
                font-family: Montserrat Alternates;
                font-size: 43px;
                font-weight: 800;
                line-height: 52px;
                letter-spacing: 0.02em;
                text-align: left;

            }
            .desc {
                font-family: Montserrat Alternates;
                font-size: 16px;
                font-weight: 500;
                line-height: 30px;
                letter-spacing: 0.02em;
                text-align: left;

            }
        }
        .box-img {
            display: block;
            margin-left: auto;
            padding-right: 64px;
            img {
                width: 100%;
                max-width: 471px;
            }
          }
    }
}